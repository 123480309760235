import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable()
export class FeeService {

  constructor(private API: ApiService) {}

  getFormData() {
    return this.API.post('fee/get_data');
  }

  submitForm(data) {
    return this.API.post('fee/save', data);
  }

  // -----------------------------------------------------------------------------
  // Requests for Interest Rates CoD
  getRatesFormData(id) {
    return this.API.post('fee/get_rates_data', {
      'id': id
    });
  }

  submitRatesForm(data) {
    return this.API.post('fee/save_rates', data);
  }

  RatesListing() {
    return this.API.post('fee/get_rates_table');
  }

  deleteRates(id) {
    return this.API.post('fee/delete_rates', {
      'id': id
    });
  }
  // End of Requests for Interest Rates CoD
  // -----------------------------------------------------------------------------
}
